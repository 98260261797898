<template>
    <b-jumbotron
        header="About"
        lead="เกี่ยวกับ n4mmon | nammonmen"
        bg-variant="dark"
        text-variant="white"
    >
      <p>
        เกี่ยวกับหรอ ชั้นคือน้ำมนต์ หรือ มน เป็นเจ้าของเพจ <router-link to="/facebook">Facebook</router-link> บน <router-link to='/youtube'>YouTube</router-link> และ <router-link to='/instagram>'>Instagram</router-link> ก็มีนะ 
      <br>
        ขอบคุณนะที่เข้ามาในเพจชั้นแต่ก็ไม่ได้มีอะไรมากกว่านี้แล้วแหละ
      </p>

      <hr class="my-4">

    </b-jumbotron>
</template>

<script>
export default {
name: "about"
}
</script>

<style scoped>

</style>